import Card from './Card'
import Color from 'color'
import React from 'react'
import styled from '@emotion/styled'

const StyledCard = styled(Card)`
  background-color: ${props => props.colorCode};
  color: ${props => props.textColor};
  cursor: pointer;

  .color-name {
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: 2rem;
  }

  .color-codes {
    display: flex;
    flex-direction: column;

    .color-code-row {
      display: flex;
      margin-bottom: 6px;
    }

    .color-code-row-key {
      width: 4rem;
      font-weight: 700;
    }
  }
`

const colorArrayToString = c => c.map(c => Math.round(c)).join(' ')

const ColorCard = ({
  colorName,
  colorCode,
  textColor = '#fff',
  drawBorder = false,
  onClick = () => {},
}) => {
  const hex = String(colorCode).toUpperCase()
  const { color: rgb } = Color(colorCode).rgb().toJSON()

  const { color: cmyk } = Color(colorCode).cmyk().toJSON()

  return (
    <StyledCard
      colorCode={colorCode}
      textColor={textColor}
      hideBorder={!drawBorder}
      onClick={onClick}
    >
      <div className="color-name">{colorName}</div>

      <div className="color-codes">
        <div className="color-code-row">
          <div className="color-code-row-key">HEX</div>
          <div className="color-code-row-value">{hex}</div>
        </div>
        <div className="color-code-row">
          <div className="color-code-row-key">RGB</div>
          <div>{colorArrayToString(rgb)}</div>
        </div>
        <div className="color-code-row">
          <div className="color-code-row-key">CMYK</div>
          <div>{colorArrayToString(cmyk)}</div>
        </div>
      </div>
    </StyledCard>
  )
}

export default ColorCard
