import BaseLayout from '../components/layout/BaseLayout'
import Card from '../components/elements/cards/Card'
import Col from 'react-bootstrap/Col'
import ColorCard from '../components/elements/cards/ColorCard'
import Container from 'react-bootstrap/Container'
import LogoBlackSVG from '../assets/svg/logo/logo-b.svg'
import LogoSVG from '../assets/svg/logo/logo.svg'
import LogoSpacingSVG from '../assets/svg/logo/logo-spacing.svg'
import LogoWhiteSVG from '../assets/svg/logo/logo-w.svg'
import React from 'react'
import Row from 'react-bootstrap/Row'
import Section from '../components/layout/Section'
import { StaticImage } from 'gatsby-plugin-image'
import WordmarkBlackSVG from '../assets/svg/logo/wordmark-b.svg'
import WordmarkBlackTextSVG from '../assets/svg/logo/wordmark-bt.svg'
import WordmarkSVG from '../assets/svg/logo/wordmark.svg'
import WordmarkSpacingSVG from '../assets/svg/logo/wordmark-spacing.svg'
import WordmarkWhiteSVG from '../assets/svg/logo/wordmark-w.svg'
import copy from 'copy-to-clipboard'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import theme from '../theme'
import makeSeoTitle from '../lib/utils/makeSeoTitle'

const dark = theme.palette.darkGrey

const COLORS = [
  { name: 'Blue', hex: theme.palette.blue, textdrawBorder: false },
  { name: 'Dark Grey', hex: theme.palette.darkGrey, drawBorder: true },
  { name: 'Grey', hex: theme.palette.grey, drawBorder: false },
  {
    name: 'Light Grey',
    hex: theme.palette.lightGrey,
    textColor: dark,
    drawBorder: false,
  },
  {
    name: 'Dark White',
    hex: theme.palette.darkWhite,
    textColor: dark,
    drawBorder: true,
  },
  {
    name: 'White',
    hex: theme.palette.white,
    textColor: dark,
    drawBorder: false,
  },
]

const PaddedCard = styled(Card)`
  padding: 40px;
`

const MarginlessCardRow = styled(Row)`
  @media screen and (min-width: 960px) {
    .card {
      margin-bottom: 0;
    }
  }
`

const logo = css`
  @media screen and (max-width: 960px) {
    display: flex;
    justify-content: center;
    svg {
      height: 128px;
    }
  }
`

const BrandingPage = () => (
  <BaseLayout>
    <Section>
      <Row>
        <Col md={6} className="vertical-center">
          <h1>Brand</h1>
          <p>
            Zwar emöglicht das Designen großen gestalterischen Freiraum,
            allerdings sollten bei der finalen Umsetzung Attribute wie Qualität
            und Genaugikeit nicht unbeabsichtigt bleiben.
          </p>
          <p>
            <b>
              Bei der Verwendung meines Designs sollte auf nachfolgende Logos,
              Farbcodes und Richtlinien geachtet werden.
            </b>
          </p>
        </Col>
        <Col md={6}>
          <StaticImage
            src="../assets/img/pages/branding/brand_blueprint.jpg"
            quality={100}
            placeholder="blurred"
            alt="EisDesigns Logo auf einem Gitternetz"
          />
        </Col>
      </Row>
    </Section>

    <Section dark color={theme.palette.white}>
      <h1>Logo</h1>
      <p
        css={css`
          margin-bottom: 2.4rem;
        `}
      >
        Das EisDesigns-Logo darf nicht bearbeitet, geändert, verzerrt, neu
        koloriert oder neu konfiguriert werden.
      </p>

      <Row>
        <Col md={6}>
          <PaddedCard>
            <WordmarkBlackTextSVG />
          </PaddedCard>
        </Col>
        <Col md={6}>
          <PaddedCard dark lightBorder>
            <WordmarkSVG />
          </PaddedCard>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <PaddedCard>
            <WordmarkBlackSVG />
          </PaddedCard>
        </Col>
        <Col md={6}>
          <PaddedCard dark lightBorder>
            <WordmarkWhiteSVG />
          </PaddedCard>
        </Col>
      </Row>
      <MarginlessCardRow>
        <Col md={3} sm={12}>
          <PaddedCard
            css={css`
              height: 150px;
            `}
          >
            <LogoSVG />
          </PaddedCard>
        </Col>
        <Col md={3} sm={12}>
          <PaddedCard
            css={css`
              height: 150px;
            `}
          >
            <LogoBlackSVG />
          </PaddedCard>
        </Col>
        <Col md={3} sm={12}>
          <PaddedCard
            css={css`
              height: 150px;
            `}
            dark
            lightBorder
          >
            <LogoWhiteSVG />
          </PaddedCard>
        </Col>
        <Col md={3} sm={12} />
      </MarginlessCardRow>

      <h1>Spacing</h1>
      <p
        css={css`
          margin-bottom: 2.4rem;
        `}
      >
        Das EisDesigns-Logo darf nicht bearbeitet, geändert, verzerrt, neu
        koloriert oder neu konfiguriert werden.
      </p>

      <Row>
        <Col md={9}>
          <WordmarkSpacingSVG />
        </Col>
        <Col md={3} css={logo}>
          <LogoSpacingSVG />
        </Col>
      </Row>

      <h1>Color</h1>
      <p
        css={css`
          margin-bottom: 2.4rem;
        `}
      >
        Klicken Sie hier unten, um meine Hex-Farbcodes in deine Zwischenablage
        zu kopieren.
      </p>

      <Container>
        <Row>
          {COLORS.map((color, idx) => (
            <Col md={4} key={idx}>
              <ColorCard
                colorName={color.name}
                colorCode={color.hex}
                drawBorder={color.drawBorder}
                textColor={color.textColor ? color.textColor : '#fff'}
                onClick={() => {
                  copy(color.hex, { format: 'text/plain' })
                }}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </Section>

    <Section>
      <Row>
        <Col md={6}>
          <StaticImage
            src="../assets/img/pages/branding/download.jpg"
            quality={100}
            placeholder="blurred"
            alt="Download Design-Kit"
          />
        </Col>
        <Col md={6} className="vertical-center">
          <h1>Download Kit</h1>
          <p>
            Sie haben bereits ein Design von mir und möchten mich daher
            freundlicherweise erwähnen? Dann hab ich hier für Sie alle meine
            Logos, Color-Codes etc. in einer übersichtlichen Mappe
            zusammengefasst.
          </p>
          <p>
            Hier können Sie mein gesamtes Brand-Kit herunterladen. (Alle Farben,
            Logos und Wordmarks in einem Ordner)
          </p>
        </Col>
      </Row>
    </Section>
  </BaseLayout>
)

export const Head = () => (
  <>
    <title>{makeSeoTitle('Branding')}</title>
    <meta
      name="description"
      content="Hier stehen EisDesigns Logo, Wordmark und viele weitere Brandressourcen zum Download bereit. Viel Spaß beim Benutzen!"
    />
  </>
)

export default BrandingPage
